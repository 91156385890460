import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import tachyons from 'tachyons-components'
import GlobalStyle from "../styles/GlobalStyle"

const SectionWrapper = tachyons('div')`
wrap pv4-ns
`
const Section = tachyons('section')`
blog w-100 dt wrap pt5 pt6-ns
`
const PageWrapper = tachyons('div')`
w-100-ns mb4
`
const ArticleHeader = tachyons('h3')`
lh-title mb1 blue mt0
`
const HeaderLink = tachyons(Link)`
fw6 link
`
const ArticleExcerpt = tachyons('p')`
lh-copy mt1 mb2
`
const ArticleLink = tachyons(Link)`
link mid-gray
`

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const postLinks = posts.map(post => (
      <PageWrapper key={post.node.fields.slug}>
        <ArticleHeader>
        <HeaderLink to={post.node.fields.slug}>
        {post.node.frontmatter.title}
        </HeaderLink>
        </ArticleHeader>
          <ArticleExcerpt>{post.node.excerpt}</ArticleExcerpt>
          <p className='mb2 mt3'>
          <ArticleLink to={post.node.fields.slug}>
          Continue reading →
          </ArticleLink>
          </p>
          <small className="mid-gray lh-copy">{post.node.frontmatter.date}</small>
      </PageWrapper>
    ))
    const tag = this.props.pageContext.tag
    const title = this.props.data.site.siteMetadata.title
    const totalCount = this.props.data.allMarkdownRemark.totalCount
    const tagHeader = `${totalCount} post${
    totalCount === 1 ? '' : 's'
    } with`
    const tagTopic = `related topic`

    return (
      <Layout>
        <GlobalStyle />
        <SectionWrapper className="wrap pv4-ns dt hr-bottom pb5 pt5 pt6-ns">
          <Section>
            <h1 className="f3 mb4 mt0">
              {tagHeader} <span className="fw6">“{tag}”</span> {tagTopic}
            </h1>
            <Helmet
              title={`${
                totalCount === 1 ? "Post" : "Posts"
              } on “${tag}” · ${title}`}
            >
              <meta
                name="description"
                content={`All the ${
                  totalCount === 1 ? "posts" : "postss"
                } with “${tag}” related topic.`}
              />
              <meta
                name="twitter:title"
                content={`${
                  totalCount === 1 ? "Post" : "Posts"
                } on “${tag}” · ${title}`}
              />
              <meta
                name="twitter:description"
                content={`All the ${
                  totalCount === 1 ? "Post" : "Posts"
                } with “${tag}” related topic.`}
              />
              <meta
                property="og:title"
                content={`${
                  totalCount === 1 ? "Post" : "Posts"
                } on “${tag}” · ${title}`}
              />
              <meta
                property="og:description"
                content={`All the ${
                  totalCount === 1 ? "Post" : "Posts"
                } with “${tag}” related topic.`}
              />
            </Helmet>
            {postLinks}
            <p className="w-100 mb5 mb6-ns bt pt3 pt4-ns hr-top">
              <Link className="link fw6 f4" to="/tags/">
                Or browse all topics →
              </Link>
            </p>
          </Section>
        </SectionWrapper>
      </Layout>
    );
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(pruneLength: 150)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
